<template>
  <BaseLayout>
    <MainHeader></MainHeader>
    <div class="hero-container">
      <div class="hero-text-container">
        <h3>WELCOME TO</h3>
        <h1>MANE STREET MARKET</h1>
        <h2>An Online Marketplace for Sales Horses, Equine Services and Equestrian Equipment</h2>
      </div>
      <div class="marquee-container">
        <img v-for="(sliderFilename, i) in sliderFilenames" :key="i" :src="require(`../assets/img/home_slider/${ sliderFilename }`)" />
      </div>
    </div>
    <div class="categories-container">
      <div class="categories-header">
        <span>Categories</span>
        <div></div>
      </div>
      <div class="categories-buttons-container">
        <div class="categories-card">
          <router-link to="/search" class="icon-link">
            <div class="icon-circle">
              <div class="icon-horses-for-sale"></div>
            </div>
          </router-link>
          <span class="categories-title">
            <router-link to="/search">Search Sales Horses</router-link>
          </span>
        </div>
        <div class="categories-card">
          <router-link to="/save-listing" class="icon-link">
            <div class="icon-circle">
              <div class="icon-list-a-horse"></div>
            </div>
          </router-link>
          <span class="categories-title">
            <router-link to="/save-listing">List A Horse</router-link>
          </span>
        </div>
        <div class="categories-card">
          <router-link to="/search-equipment?type=Any" class="icon-link">
            <div class="icon-circle">
              <div class="icon-equipment"></div>
            </div>
          </router-link>
          <span class="categories-title">
            <router-link to="/search-equipment?type=Any">Search</router-link>
            or
            <router-link to="/save-equipment">List Equipment</router-link>
          </span>
        </div>
        <div class="categories-card">
          <router-link to="/search-service-providers?type=Any" class="icon-link">
            <div class="icon-circle">
              <div class="icon-service-providers"></div>
            </div>
          </router-link>
          <span class="categories-title">
            <router-link to="/search-service-providers?type=Any">Search</router-link>
            or
            <router-link to="/save-service">List Equine Services</router-link>
          </span>
        </div>
      </div>
    </div>
    <div class="testimonials-container">
      <h2 class="caps">Testimonials</h2>
      <div class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides">
            <li class="glide__slide">
              <span>"Mane Street Market is amazing! I posted my horse and had my first inquiry within the first hour!"</span>
              <span>Sofia Frontiere (Georgia)</span>
            </li>
            <li class="glide__slide">
              <span>"Defasio sold to someone who saw the Mane Street Market post on Facebook! Thank you so much!"</span>
              <span>Aqua Rask (California)</span>
            </li>
            <li class="glide__slide">
              <span>"Onner V sold! Mane Street Market is the only place I listed her - thank you so much!"</span>
              <span>Tamara Brown (South Carolina)</span>
            </li>
            <li class="glide__slide">
              <span>"My little pinto sold in 2 days of being listed on Mane Street Market. Thank you!"</span>
              <span>Rebecca Bowman (Tennessee)</span>
            </li>
            <li class="glide__slide">
              <span>"Will just sold thanks to Mane Street Market. He's found the most fantastic home in Virginia. Thank you!"</span>
              <span>Nicky Buckingham (Florida)</span>
            </li>
            <li class="glide__slide">
              <span>"Thank you Mane Street Market for all you have done for us! We have 2 new clients because of your brilliant idea come to life!"</span>
              <span>Debra Marie (Georgia)</span>
            </li>
            <li class="glide__slide">
              <span>"I was introduced to Mane Street Market through friends. I was blown away by all the social media attention they gave my mare and when I let them know she had found a new home, they wrote me back with such a kind note about how happy they were! It was almost if we were all on the same team finding my mare the best possible home. I highly recommend MSM - I wish all businesses cared this much"</span>
              <span>Paula Dunson (Maryland)</span>
            </li>
            <li class="glide__slide">
              <span>"The MSM app makes it so easy to share horses - I just click the "share" option and send the ad directly to my trainer. Saves so much time!"</span>
              <span>Emily Haight (Georgia)</span>
            </li>
            <li class="glide__slide">
              <span>"I sold my bridle within a few days of being listed on Mane Street Market. The entire process was so simple!"</span>
              <span>Lindsey Parker (Texas)</span>
            </li>
          </ul>
        </div>
        <div class="testimonial-slide-controls" data-glide-el="controls">
          <button data-glide-dir="<">
            <i class="fa-solid fa-chevron-left"></i>
          </button>
          <button data-glide-dir=">">
            <i class="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="branded-apparel-container">
      <a href="https://grandimpressiondesign.com/collections/mane-street-market" target="_blank" >
        <img src="../assets/img/branded_apparel.jpg" />
      </a>
    </div>
    <div class="apps-intro-container">
      <div class="buyer-seller-intro">
        <div>
          <h3>For the buyer:</h3>
          <ul class="pl-3">
            <li>Search for sales horses, equine services as well as tack, boots, clothes and equipment</li>
            <li>Easy to contact seller directly from the website or app</li>
            <li>Ability to save searches</li>
          </ul>
        </div>
        <div class="app-image">
          <img src="../assets/img/app_image_2.png" height="422" />
        </div>
        <div>
          <h3>For the seller:</h3>
          <ul class="pl-3">
            <li>Simple to upload your ad including photos and videos</li>
            <li>Minimal investment with maximum exposure for your ad with an average of over 27,000 website visits and 1,000 app opens per month</li>
            <li>Active social media promoting your ads on Facebook, Instagram and LinkedIn</li>
            <li>Newsletter promoting horses, services and equipment sent 2 times per month with over 5,000 recipients</li>
          </ul>
        </div>
      </div>
      <div class="text-center" style="margin: 60px 0;">
        <h3 class="blue-top-border">
          <span></span>Download the App
        </h3>
        <a href="https://apps.apple.com/us/app/id1552998491" target="_blank">
          <img src="../assets/img/appstore_button.png" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.joshyap.manestreetmarket" target="_blank" style="margin-left: 1rem;">
          <img src="../assets/img/playstore_button.png" />
        </a>
      </div>
    </div>
    <div class="newsletter-container">
      <div>
        <span>Stay up to date on all of the exciting things happening at Mane Street Market!</span>
        <a-input v-model:value="subscription_email" placeholder="Enter Email Address" @keyup.enter="subscribeEmail" />
        <a-button type="primary" class="btn btn-primary" :loading="loading" :disabled="loading" @click="subscribeEmail">{{ loading ? '' : 'Submit' }}</a-button>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import MainHeader from "@/components/MainHeader.vue";
import AInput from 'ant-design-vue/lib/input';
import AButton from 'ant-design-vue/lib/button';
import 'ant-design-vue/lib/input/style/index.css';
import axios from 'axios';
import Swal from "sweetalert2";
import InfiniteMarquee from 'vanilla-infinite-marquee';
import 'vanilla-infinite-marquee/infinite-marquee.css';
import Glide from '@glidejs/glide';

export default {
  components: {
    MainHeader,
    BaseLayout,
    AInput,
    AButton
  },
  data() {
    return {
      subscription_email: '',
      loading: false,
      sliderFilenames: []
    }
  },
  mounted() {
    document.title = 'Mane Street Market';
    window.scrollTo(0, 0);

    const sliderFiles = require.context('@/assets/img/home_slider', false);
    this.sliderFilenames = sliderFiles.keys().map(key => key.slice(2));

    setTimeout(() => {
      new InfiniteMarquee({
        element: '.marquee-container',
        speed: 80000,
        spaceBetween: '4px',
        duplicateCount: 2
      });
    });

    const glide = new Glide('.glide', {
      autoplay: 5000,
      hoverpause: false
    });

    const glideHandleHeight = () => {
      const activeSlide = document.querySelector('.glide__slide--active');
      const activeSlideHeight = activeSlide ? activeSlide.offsetHeight : 0;

      const glideTrack = document.querySelector('.glide__track');
      const glideTrackHeight = glideTrack ? glideTrack.offsetHeight : 0;

      if (activeSlideHeight !== glideTrackHeight) {
        glideTrack.style.height = `${activeSlideHeight}px`;
      }
    }

    glide.on('build.after', function () {
      glideHandleHeight();
    });

    glide.on('run.after', function () {
      glideHandleHeight();
    });

    glide.mount();
  },
  methods: {
    subscribeEmail(e) {
      e.preventDefault();
      if (!this.isFormValid()) {
        return;
      }
      this.loading = true;
      axios.post('/api/subscribe-newsletter', { email: this.subscription_email }).then(() => {
        Swal.fire({
          title: 'You are subscribed!',
        });
        this.subscription_email = '';
      }).catch(err => {
        if (err?.response?.data?.error?.message) {
          err = err.response.data.error.message;
        } else {
          err = 'An error has occurred. Please try again.';
        }
        Swal.fire({
          title: 'Error subscribing',
          text: err
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    isFormValid() {
      let errorMsg = null;
      if (!this.subscription_email || this.subscription_email.trim().length === 0) {
        errorMsg = 'Please fill in an email';
      }
      if (errorMsg) {
        Swal.fire({
          title: 'There was an error with your input',
          text: errorMsg
        });
        return false;
      }
      return true;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
  @import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
  @import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

  .italics {
    font-style: italic;
  }

  .caps {
    text-transform: uppercase;
  }

  .hero-text-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    padding: 32px 48px 24px;
    text-align: center;
    color: #303661;

    h2 {
      line-height: 1.5;
    }
  }

  ::v-deep(.horizontal-marquee-inner) {
    height: 400px;
  }

  ::v-deep(.horizontal-marquee-inner img) {
    height: 100%;
  }

  .categories-container {
    margin: 80px 0 64px;
  }

  .categories-header {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .categories-header span {
    background-color: #051548;
    color: white;
    z-index: 1;
    padding: 4px 16px;
    text-transform: uppercase;
  }

  .categories-header div {
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: lightgray;
    top: 50%;
    z-index: 0;
  }

  .categories-buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 90%;
    max-width: 1140px;
    gap: 24px;
    margin: auto;
    margin-top: 64px;
  }

  .categories-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .categories-title {
    color: #050930;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
    margin-top: 32px;
    text-align: center;
  }

  .categories-title a {
    color: inherit;
  }

  .icon-link {
    width: 100%;
    aspect-ratio: 1;
    max-width: 100px;
  }

  .icon-circle {
    width: 100%;
    height: 100%;
  }

  .icon-circle > div {
    background-color: #050930;
    width: 100%;
    height: 100%;
    mask-repeat: no-repeat;
  }

  .icon-horses-for-sale {
    -webkit-mask: url('../assets/img/home_circles/financing.png') center / contain;
    mask: url('../assets/img/home_circles/financing.png') center / contain;
  }

  .icon-list-a-horse {
    -webkit-mask: url('../assets/img/home_circles/list-a-horse.png') center/contain;
    mask: url('../assets/img/home_circles/list-a-horse.png') center/contain;
  }

  .icon-equipment {
    -webkit-mask: url('../assets/img/home_circles/horses-for-sale.png') center/contain;
    mask: url('../assets/img/home_circles/horses-for-sale.png') center/contain;
  }
  
  .icon-service-providers {
    -webkit-mask: url('../assets/img/home_circles/service-providers.png') center/contain;
    mask: url('../assets/img/home_circles/service-providers.png') center/contain;
  }

  .newsletter-container {
    text-align: center;
    background-color: whitesmoke;
    padding: 48px 64px 64px;
    display: flex;
    justify-content: center;
    margin-bottom: -32px;
  }

  .newsletter-container > div {
    width: 500px;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .newsletter-container input {
    width: 100%;
    padding: 16px 8px;
  }

  .newsletter-container button {
    background-color: #051548;
    border: none;
    outline: none;
  }

  .testimonials-container {
    background-color: #C1C1C1;
    padding: 48px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .testimonials-container h2 {
    color: #303661;
  }

  .testimonials-container .glide {
    width: 90%;
    max-width: 720px;
  }

  .testimonial-slide-controls {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 36px;
  }

  .testimonial-slide-controls button {
    background: none;
    outline: none;
    border: none;
    appearance: none;
  }

  .testimonial-slide-controls button {
    font-size: 32px;
  }

  .glide__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .glide__slide span {
    text-align: center;
  }

  .glide__slide span:first-child {
    font-weight: bold;
  }

  .glide__track {
    transition: height 250ms ease-in-out;
  }

  .apps-intro-container {
    margin: 80px auto;
    width: 90%;
    max-width: 1140px;
  }

  .apps-intro-container .blue-top-border {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
  }

  .apps-intro-container a {
    display: inline-flex;
    width: calc(50% - 1rem / 2);
    max-width: 160px;
  }

  .apps-intro-container a img {
    width: 100%;
  }

  .buyer-seller-intro {
    display: flex;
    justify-content: space-between;
  }

  .buyer-seller-intro > div:first-child, .buyer-seller-intro > div:last-child {
    padding-top: 90px;
    flex: 1;
  }

  .buyer-seller-intro > div:first-child {
    margin-right: 32px;
  }

  .buyer-seller-intro > div:last-child {
    margin-left: 32px;
  }

  .buyer-seller-intro li {
    margin-bottom: 8px;
  }

  .branded-apparel-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 100px;
  }

  .branded-apparel-container a {
    overflow: hidden;
  }

  .branded-apparel-container a img {
    width: 100%;
    max-width: 1100px;
    transition: all 0.5s;
  }

  .branded-apparel-container a img:hover {
    transform: scale(1.02);
    filter: brightness(0.9);
  }

  @media screen and (max-width: 750px) {
    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.5rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    .icon-circle {
      max-width: 80px;
    }

    ::v-deep(.horizontal-marquee-inner) {
      height: 200px;
    }

    .categories-buttons-container {
      grid-template-columns: 1fr 1fr;
      row-gap: 64px;
    }

    .apps-intro-container {
      margin: 48px auto 80px;
    }

    .app-image {
      align-self: center;
      margin-bottom: 48px;
    }

    .buyer-seller-intro {
      flex-direction: column;
      gap: 16px;

      > div:not(:nth-child(2)) {
        padding: 0;
        margin: 0;
      }

      > div:nth-child(2) {
        order: 1;
      }

      > div:nth-child(1) {
        order: 2;
      }

      > div:nth-child(3) {
        order: 3;
      }
    }

    .newsletter-container {
      padding: 48px 5% 64px;
    }

    .branded-apparel-container {
      margin-top: 48px;
      margin-bottom: 64px;
    }
  }
</style>